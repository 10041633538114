<template>
  <div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-start mb-2">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        @click="createModal"
        v-b-modal.modal-create
        size="lg"
        :disabled="isDisabled"
      >
        Создать форму
      </b-button>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="getFormsList"
      :rtl="direction"
      styleClass="vgt-table"
      class="projects-table"
    >
      <div slot="emptystate" class="text-center">
        Создайте первую лид-форму
      </div>
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.field === 'name'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.name }}</span>
        </span>

        <!-- Column: Shows -->
        <span v-else-if="props.column.field === 'answers'">
          <span class="text-nowrap">
            {{ props.row.answers }}
            <feather-icon icon="EyeIcon" class="mr-50" />
          </span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span class="d-flex justify-content-around">
            <feather-icon
              icon="Edit2Icon"
              class="mr-50"
              @click="editModal(props.row)"
              v-b-modal.modal-create
              size="18"
            />
            <feather-icon
              icon="TrashIcon"
              class="mr-50 cursor-pointer"
              @click="deleteItem(props.row)"
              size="18"
            />
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <b-modal
      id="modal-create"
      cancel-variant="outline-secondary"
      :ok-title="modalType === 'addModal' ? 'Создать' : 'Сохранить'"
      cancel-title="Закрыть"
      centered
      :title="
        modalType === 'addModal' ? 'Создать форму' : 'Редактировать форму'
      "
      @ok="
        modalType === 'addModal'
          ? $emit('createItem', {
              name: createFormName,
              title: createFormTitle,
              sucсessText: createFormSuccess,
              questions: questionsList,
            })
          : $emit('editItem', {
              name: createFormName,
              questions: questionsList,
              id: editFormId,
              sucсessText: createFormSuccess,
              title: createFormTitle,
            })
      "
      @hide="resetModal"
    >
      <b-form>
        <b-form-group>
          <label for="name">Название:</label>
          <b-form-input
            id="name"
            placeholder="Введите название"
            v-model="createFormName"
          />
        </b-form-group>
        <b-form-group>
          <label for="name">Заголовок Лид Формы:</label>
          <b-form-input
            id="name"
            placeholder="Введите заголовок"
            v-model="createFormTitle"
          />
        </b-form-group>
        <label> Поля: </label>
        <draggable
          v-model="questionsList"
          class="list-group list-group-flush cursor-move"
          tag="ul"
        >
          <transition-group type="transition" name="flip-list">
            <b-list-group-item
              class="d-flex"
              v-for="question in questionsList"
              :key="question.id"
              tag="li"
            >
              <b-row class="w-2/6">
                <b-col>
                  <label for="question-type"> Тип </label>
                  <b-form-select
                    id="question-type"
                    v-model="question.type"
                    :options="questionOptionsList"
                    :disabled="true"
                  >
                  </b-form-select>
                </b-col>
              </b-row>
              <b-row class="w-2/6">
                <b-col>
                  <label for="question-title"> Название поля </label>
                  <b-form-input
                    id="question-title"
                    v-model="question.title"
                    placeholder="Введите название"
                  >
                  </b-form-input>
                </b-col>
              </b-row>
              <b-row class="w-1/6">
                <b-col> </b-col>
              </b-row>
              <b-row class="w-1/6">
                <b-col class="w-100">
                  <label for="question-enabled">Активно</label>
                  <b-form-checkbox
                    v-model="question.enabled"
                    style="margin-top: 0.5rem; margin-left: 1rem !important; width: fit-content;"
                  >
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-list-group-item>
          </transition-group>
        </draggable>
        <b-form-group class="mt-2">
          <label for="name">Текст после отправки формы:</label>
          {{ createFormSuccess }}
          <b-form-input
            id="name"
            placeholder="Введите сообщение"
            v-model="createFormSuccess"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BForm,
  BRow,
  BCol,
  BFormCheckbox,
  BListGroupItem,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BForm,
    BRow,
    BCol,
    BFormCheckbox,
    draggable,
    BListGroupItem,
  },
  directives: {
    Ripple,
  },
  props: ["forms", "isDisabled"],
  data() {
    return {
      dir: false,
      columns: [
        {
          label: "ID",
          field: "displayId",
        },
        {
          label: "Название",
          field: "name",
        },
        {
          label: "Заявки",
          field: "answers",
        },
        {
          label: "Действия",
          field: "action",
        },
      ],
      rows: [],
      createFormName: "",
      createFormTitle: "",
      createFormSuccess: "Спасибо, Ваша заявка принята",
      questionsList: [
        {
          id: this.randomKey(),
          type: "name",
          title: "Имя",
          enabled: true,
        },
        {
          id: this.randomKey(),
          type: "phone",
          title: "Телефон",
          enabled: false,
        },
        {
          id: this.randomKey(),
          type: "email",
          title: "E-mail",
          enabled: true,
        },
        {
          id: this.randomKey(),
          type: "string",
          title: "Произвольный вопрос",
          enabled: false,
        },
      ],
      questionOptionsList: [
        { value: "name", text: "Имя" },
        { value: "phone", text: "Телефон" },
        { value: "email", text: "E-mail" },
        { value: "string", text: "Свой вопрос" },
      ],
      modalType: "addModal",
      editFormId: "",
    };
  },
  computed: {
    ...mapGetters("lead-forms", ["getFormsList"]),
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  methods: {
    randomKey() {
      let r = (Math.random() + 1).toString(36).substring(2);
      return r;
    },
    addQuestion() {
      let r = (Math.random() + 1).toString(36).substring(2);
      this.questionsList.push({
        id: r,
        type: null,
        title: "",
      });
    },
    createModal() {
      this.resetModal();
      this.modalType = "addModal";
    },
    editModal(data) {
      console.log(data);
      this.questionsList = [...data.questions];
      this.createFormName = data.name;
      this.createFormTitle = data.title;
      this.createFormSuccess = data.sucсessText;
      this.editFormId = data.id;
      this.modalType = "editModal";
    },
    resetModal() {
      this.questionsList = [
        {
          id: this.randomKey(),
          type: "name",
          title: "Имя",
          enabled: false,
        },
        {
          id: this.randomKey(),
          type: "phone",
          title: "Телефон",
          enabled: false,
        },
        {
          id: this.randomKey(),
          type: "email",
          title: "E-mail",
          enabled: false,
        },
        {
          id: this.randomKey(),
          type: "string",
          title: "Произвольный вопрос",
          enabled: false,
        },
      ];
      this.createFormName = "";
      this.createFormSuccess = "Спасибо, Ваша заявка принята";
    },
    deleteItem(row) {
      this.$swal({
        title: "Вы действительно хотите удалить форму?",
        text: "После удаления, её невозможно будет восстановить",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Да, удалить!",
        cancelButtonText: "Отменить",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$emit("deleteItem", row);
        }
      });
    },
  },
  created() {
    console.log(this.getFormsList);
  },
};
</script>

<style>
.list-group-item {
  transition: all 1s;
}
.projects-table tr:hover {
  background-color: #f8f8f8;
}
</style>
