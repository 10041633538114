<template>
  <div>
    <overview-table
      :forms="getFormsList"
      :isDisabled="getIsDisabled"
      @createItem="createLeadForm"
      @deleteItem="deleteItem"
      @editItem="editItem"
    />
  </div>
</template>

<script>
import OverviewTable from "./OverviewTable.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { OverviewTable },
  data() {
    return {};
  },
  metaInfo() {
    return {
      title: "moovee - Лид-формы",
    };
  },
  computed: {
    ...mapGetters("lead-forms", ["getFormsList"]),
    ...mapGetters("user", ["getIsDisabled"]),
  },
  methods: {
    ...mapActions("lead-forms", [
      "requestForms",
      "addForms",
      "deleteForm",
      "editForm",
    ]),
    ...mapActions("user", ["requestUserData"]),
    createLeadForm(formData) {
      this.addForms(formData);
    },
    deleteItem(item) {
      this.deleteForm(item);
    },
    editItem(item) {
      console.log(item);
      this.editForm(item);
    },
  },
  mounted() {
    this.requestUserData();
    this.requestForms();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
